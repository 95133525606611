<template>
  <auth>
    <div class="Login card">
      <div class="card-content">
        <heading size="4" class="has-text-centered">
          {{ $t('pages.login.title') }}
        </heading>

        <transition name="fade">
          <div v-if="errorMsg">
            <div class="notification is-danger error-msg m-b-m">
              {{ errorMsg }}
            </div>
            <div
              v-if="status === 403"
              class="has-text-centered"
            >
              <v-button
                :loading="isResendingActivation"
                class="is-primary m-b-m"
                @click="resendActivation"
              >
                {{ $t('components.login.resend_activation') }}
              </v-button>
            </div>
          </div>
        </transition>

        <form @submit.prevent="login">
          <form-field
            :validator="$v.form.email"
            :label="$t('forms.your_email')"
            left-icon="envelope"
          >
            <input
              v-model="form.email"
              slot-scope="{ classes }"
              :class="['input has-icon', classes]"
              type="email"
              name="email"
              data-testid="LoginInput__Email"
            >
          </form-field>
          <form-field
            :validator="$v.form.password"
            :label="$t('forms.password')"
            left-icon="lock"
          >
            <input
              v-model="form.password"
              slot-scope="{classes}"
              :class="['input has-icon', classes]"
              type="password"
              name="password"
              data-testid="LoginInput__Password"
            >
          </form-field>
          <v-button
            :loading="isLoading"
            class="is-black is-medium is-fullwidth m-t-m m-b-m"
            data-testid="Login__Submit"
          >
            {{ $t('components.login.login') }}
          </v-button>
          <div class="has-text-right m-b-m">
            <router-link
              :to="{ name: 'forgotten-password' }"
              class="forgot-password m-b-l has-text-weight-bold"
            >
              {{ $t('forms.forgot_password') }}
            </router-link>
          </div>
        </form>
      </div>

      <div class="login-footer has-background-grey-lighter p-a-l has-text-left">
        <heading
          :serif="false"
          color="black"
          size="5"
        >
          {{ $t('components.login.create_an_account') }}
        </heading>

        <div class="columns">
          <div class="column">
            <div class="m-b-s">
              {{ $t('components.login.newsroom_signup') }}
            </div>
            <router-link
              :to="{ name: 'NewsroomRegister' }"
              class="button is-primary"
            >
              {{ $t('components.login.signup') }}
            </router-link>
          </div>
          <div class="column">
            <div class="m-b-s">
              {{ $t('components.login.journalist_signup') }}
            </div>
            <router-link
              :to="{ name: 'JournalistRegister' }"
              class="button is-primary"
            >
              {{ $t('components.login.signup') }}
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </auth>
</template>

<script>
import { required, minLength, email } from 'vuelidate/lib/validators'
import { mapActions, mapGetters } from 'vuex'

import Auth from './Auth'
import { errorLoggingService } from '@hypefactors/shared/js/services/errorLoggingService'

/**
 * @module Login
 */
export default {
  name: 'Login',
  components: { Auth },
  data () {
    return {
      form: {
        email: null,
        password: null
      },
      errorMsg: '',
      isResendingActivation: false,
      isLoading: false,
      status: null
    }
  },

  validations: {
    form: {
      email: { email, required },
      password: { required, min: minLength(6) }
    }
  },

  computed: {
    ...mapGetters(['currentUser', 'isLoggedIn']),
    ...mapGetters('personalNewsrooms', ['firstNewsroom'])
  },

  mounted () {
    this.form.email = this.form.email || this.$route.query.signin
    this.isLoggedIn && this.closeAllAuthModals()
  },

  methods: {
    ...mapActions(['loginUserRequest']),
    login () {
      this.$v.$touch()

      if (this.$v.$error) {
        return false
      }

      this.resetErrors()
      this.isLoading = true
      this.loginUserRequest(this.form).then(() => {
        const user = this.currentUser
        errorLoggingService.identify(user)
        this.$ma.setUsername(user.id)
        this.$notify.success(this.$t('success.logged_in_as', { user: `${user.first_name} ${user.last_name}` }))
        let path = { name: 'home' }
        if (this.$route.query.redirect) {
          path = { path: this.$route.query.redirect }
        } else if (user.type === 'company' && this.firstNewsroom) {
          path = { name: 'newsroom.edit', params: { newsroomSlug: this.firstNewsroom.slug } }
        } else if (user.type === 'reader') {
          path = { name: 'dashboard' }
        }
        this.$router.push(path)
      }).catch(error => {
        if (error.response) {
          this.errorMsg = error.response.data.message
          this.status = error.response.status
        }
        // skip error reporting if we have a 404
        this.$displayRequestError(error, this.$t('errors.user_could_not_be_authenticated'), 5000, [404])
      }).finally(() => {
        this.isLoading = false
      })
    },
    resetErrors () {
      this.errorMsg = ''
      this.status = null
    },
    async resendActivation () {
      try {
        await this.$confirm(this.$t('components.login.confirm_resend_activation'))
        this.isResendingActivation = true
        await this.$api.post('activation/resend', { email: this.form.email })
        this.resetErrors()
        this.$notify.success(this.$t('success.successfully_sent_new_email_with_token'))
      } catch (error) {
        if (error === 'cancel') return
        this.$displayRequestError(error)
      } finally {
        this.isResendingActivation = false
      }
    }
  }
}
</script>
